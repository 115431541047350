import { logger } from '@springtree/eva-sdk-core-logger';
import { createServiceDefinition, Core, IEvaServiceDefinition } from '@springtree/eva-services-core';
import { EvaService, IEvaServiceCallOptions } from './service';

/**
 * The data object representing a bootstrapped endpoint
 * Can be used to serialise/deserialise to a Storage provider
 *
 * @export
 * @interface IEvaEndpointSerializedData
 */
export interface IEvaEndpointSerializedData {
  endPointUri: string;
  applications: EVA.Core.ListApplicationsResponse|undefined;
  configuration: EVA.Core.GetApplicationConfigurationResponse|undefined;
  currentApplication: EVA.Core.ApplicationDto|undefined;
  bootstrappedAt: number;
}

/**
 * Provides bootstrap logic for an EVA endpoint.
 * We need to bootstrap to get access to applications and configuration
 * This provides us with details such as the default token, base urls, etc.
 * We can bootstrap both cloud and on-premise EVA endpoints the same way
 *
 * @export
 * @class EvaEndpoint
 */
export class EvaEndpoint {

  /**
   * The endpoint URL for the EVA backend
   *
   * @type {string}
   */
  public readonly endPointUri: string;

  /**
   * The list of applications available at the EVA endpoint
   *
   * @type {EVA.Core.ListApplicationsResponse}
   */
  private applications: EVA.Core.ListApplicationsResponse|undefined;

  /**
   * The currently selected application
   *
   * @type {EVA.Core.ApplicationDto|undefined}
   */
  private currentApplication: EVA.Core.ApplicationDto|undefined;

  /**
   * The default configuration for the EVA endpoint
   *
   * @private
   * @type {EVA.Core.GetApplicationConfigurationResponse}
   */
  private configuration: EVA.Core.GetApplicationConfigurationResponse|undefined;

  /**
   * Unix timestamp in milliseconds when the bootstrap was done
   *
   * @private
   * @type {number}
   */
  public bootstrappedAt: number = 0;

  /**
   * Getter for boolean state indicating the endpoint has been bootstrapped
   *
   * @readonly
   * @type {boolean}
   */
  public get hasBootstrapped(): boolean {
    return !!this.applications;
  }

  /**
   * Returns the ID of the currently selected application
   *
   * @readonly
   * @type {(number|undefined)}
   */
  public get currentApplicationId(): number|undefined {
    return this.currentApplication ? this.currentApplication.ID : undefined;
  }

  /**
   * Select a new current application by ID
   *
   * @param {number} targetApplicationId
   */
  public selectApplication(targetApplicationId: number) {
    if (this.applications) {
      const newApplication = this.applications.Result.find((application) => {
        return application.ID === targetApplicationId;
      });
      if (!newApplication) {
        throw new Error(`Endpoint ${this.endPointUri} does not contain an application with ID ${targetApplicationId}`);
      }
      this.currentApplication = newApplication;
    } else {
      throw new Error('Endpoint has not been bootstrapped');
    }

    // Use member function to return a copy
    //
    return this.getCurrentApplication();
  }

  /**
   * Creates an instance of EvaEndpoint
   * Can either be a simple endpoint or serialised data
   *
   * @param {string} endPointUri The uri for the EVA endpoint to bootstrap
   */
  constructor(
    endPointUriOrSerialisedData: string|IEvaEndpointSerializedData,
  ) {
    if (typeof endPointUriOrSerialisedData === 'string') {
      this.endPointUri = endPointUriOrSerialisedData;
    } else {
      const data: IEvaEndpointSerializedData = endPointUriOrSerialisedData;
      this.endPointUri = data.endPointUri;
      this.applications = data.applications;
      this.configuration = data.configuration;
      this.currentApplication = data.currentApplication;
      this.bootstrappedAt = data.bootstrappedAt;
    }
  }

  /**
   * Create a data object representation of the EVA Endpoint instance
   *
   * @returns {IEvaEndpointSerializedData}
   */
  public serialise(): IEvaEndpointSerializedData {
    const data: IEvaEndpointSerializedData = {
      endPointUri: this.endPointUri,
      applications: this.applications,
      configuration: this.configuration,
      currentApplication: this.currentApplication,
      bootstrappedAt: this.bootstrappedAt,
    };

    return data;
  }

  /**
   * Perform the bootstrap calls and setup current application
   *
   * @param {number} [applicationId] A specific application id to select
   */
  public async bootstrap(
    targetApplicationId?: number,
  ) {
    logger.debug(`[EVA:ENDPOINT] Bootstrapping ${this.endPointUri}...`);

    // We will disable the EVA service interceptors to not hit on premise or
    // other behaviour changing logic. This logic should not work before the
    // bootstrap anyway
    //
    const listApplications = new EvaService<Core.ListApplications>(
      createServiceDefinition(Core.ListApplications),
      this,
      { disableInterceptors: true, disableEndpointBootstrapCheck: true },
    );

    // Fetch the applications
    //
    const applicationList = await listApplications.call();
    this.applications = applicationList.response;

    // Select current application
    //
    if (this.applications && this.applications.Result && this.applications.Result.length) {
      if (targetApplicationId) {
        const application = this.applications.Result.find((application) => {
          return application.ID === targetApplicationId;
        });
        if (!application) {
          throw new Error(`Endpoint ${this.endPointUri} does not contain an application with ID ${targetApplicationId}`);
        }
        this.currentApplication = application;
      } else {
        this.currentApplication = this.applications.Result[0];
      }
    } else {
      throw new Error(`Endpoint ${this.endPointUri} does not contain any applications`);
    }

    // Fetch application configuration
    //
    const authenticationToken = this.getDefaultToken();
    if (!authenticationToken) {
      throw new Error('Failed to bootstrap. Application lacks authentication token');
    }
    const getApplicationConfiguration = new EvaService<Core.GetApplicationConfiguration>(
      createServiceDefinition(Core.GetApplicationConfiguration),
      this,
      { disableInterceptors: true, disableEndpointBootstrapCheck: true },
    );

    const applicationConfiguration = await getApplicationConfiguration.call(
      {
        authenticationToken,
      },
    );
    this.configuration = applicationConfiguration.response;

    this.bootstrappedAt = +(new Date());
    logger.debug(`[EVA:ENDPOINT] Completed bootstrap for ${this.endPointUri}`);
  }

  /**
   * Return the list of all applications available on the EVA endpoint
   * Will not be populated until bootstrap is called
   *
   * @returns {EVA.Core.ListApplicationsResponse|undefined}
   */
  public getApplications(): EVA.Core.ListApplicationsResponse|undefined {
    // Return a copy so callers can't modify internal data
    //
    return this.applications ? JSON.parse(JSON.stringify(this.applications)) : undefined;
  }

  /**
   * Returns the currently selected application
   * Will not be populated until bootstrap is called
   *
   * @returns {(EVA.Core.ApplicationDto|undefined)}
   */
  public getCurrentApplication(): EVA.Core.ApplicationDto|undefined {
    // Return a copy so callers can't modify internal data
    //
    return this.currentApplication ? JSON.parse(JSON.stringify(this.currentApplication)) : undefined;
  }

  /**
   * Returns the current application configuration
   * Will not be populated until bootstrap is called
   *
   * @returns {(EVA.Core.GetApplicationConfigurationResponse|undefined)}
   */
  public getApplicationConfiguration(): EVA.Core.GetApplicationConfigurationResponse|undefined {
    // Return a copy so callers can't modify internal data
    //
    return this.configuration ? JSON.parse(JSON.stringify(this.configuration)) : undefined;
  }

  /**
   * Retrieves the authentication token for an application from the ListApplicationsResponse
   * Default usage is to return from the current application
   *
   * @param {*} [targetApplicationId] Target application if not using current
   * @returns {(string|undefined)}
   */
  public getDefaultToken(
    targetApplicationId?: number,
  ): string|undefined {
    if (this.applications && this.applications.Result && targetApplicationId) {
      const application = this.applications.Result.find((application) => {
        return application.ID === targetApplicationId;
      });
      if (!application) {
        throw new Error(`Endpoint ${this.endPointUri} does not contain an application with ID ${targetApplicationId}`);
      }
      return application.AuthenticationToken;
    }
    if (this.currentApplication) {
      return this.currentApplication.AuthenticationToken;
    }
  }

  /**
   * Prepare to call the EVA backend with an instance of the EvaService class
   *
   * @template T
   * @param {new () => T} serviceDefinition
   * @returns EvaService<T>
   */
  public prepareService<T extends IEvaServiceDefinition>(serviceDefinition: new () => T): EvaService<T> {
    return new EvaService<T>(
      createServiceDefinition(serviceDefinition),
      this,
    );
  }

  /**
   * Calls an EVA servince on the endpoint
   *
   * @template T
   * @param {new () => T} serviceDefinition
   * @param {Pick<T, 'request'>} [payload]
   * @param {IEvaServiceCallOptions} [options]
   * @returns {Promise<Pick<T, 'response'>>}
   */
  public async callService<T extends IEvaServiceDefinition>(
    serviceDefinition: new () => T,
    payload?: T['request'],
    options?: IEvaServiceCallOptions,
  ): Promise<T['response']> {
    // Build the EVA Service instance using the typed prepare method
    //
    const service = this.prepareService(serviceDefinition);

    // Set the request payload
    //
    service.data.request = payload;

    // Call using provided options
    //
    const result = await service.call(options);
    return result.response as Pick<T, 'response'>;
  }
}
